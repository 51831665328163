.mat-tab-group {
  font-family: $font1;

  .mat-tab-label, .mat-tab-link {
    font-weight: 600;
  }

  .mat-tab-nav-bar, .mat-tab-header {
    border-bottom: none;
  }
  .mat-tab-label-container {
    .mat-tab-label {
      opacity: 1;
      height: 40px;
      min-width: 0;
      margin-right: 1px;
      background: map_get($tabs, bg);
      text-transform: uppercase;
      &:hover, &:focus {
        background: darken(map_get($tabs, bg), 10%);
      }
    }
    .mat-ink-bar {
      display: none;
    }
    .mat-tab-label-active {
      background: map_get($tabs, active);
      border-radius: 4px 4px 0 0;
      &:hover, &:focus {
        background: map_get($tabs, active);
      }
    }
  }
  .mat-tab-header {
    margin-right: 65px;
  }
  @media screen and (max-width: 1200px) {
    .mat-tab-header {
      margin-right: 0px;
    }
  }
}

.mat-tab-group.tab-group-has-action-button {
  .mat-tab-header {
    margin-right: 220px;
  }
  @media screen and (max-width: 1200px) {
    .mat-tab-header {
      margin-right: 0px;
    }
  }
}



.mat-tab-body {
  padding: 25px 20px;
  background-color: map_get($tabs, bodyBg);
}

.mat-tab-body-content, .mat-tab-body-wrapper, .mat-tab-body.mat-tab-body-active
{
  overflow: visible !important;
}
